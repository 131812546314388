import React, { useCallback } from 'react'
import { graphql } from 'gatsby'
import {
  GenericTemplate,
  usePrismicPreviewPageData,
} from '@walltowall/gatsby-theme-ww-prismic'

import { SpeedBump } from 'src/components'

import { slicesMap as closureSlicesMap } from 'src/slices/ClosureBody'
import { slicesMap as pageSlicesMap } from 'src/slices/PageBody'

const slicesMap = { ...pageSlicesMap, ...closureSlicesMap }

export const ClosureTemplate = ({ data: rawData, location, ...props }) => {
  const data = usePrismicPreviewPageData({
    data: rawData,
    location,
  })

  const closureData = data.prismicClosure?.data
  const airportData = closureData?.airport?.document?.data
  const arcGisFeature = data.arcGisFeature

  const slicesMiddleware = useCallback(
    (list = []) => [
      { __typename: 'PageBodyHeader', id: 'header' },
      {
        __typename: 'PageBodySectionHeader',
        id: 'section-header',
        primary: {
          heading: { text: closureData?.title?.text },
          back_button_text: { text: 'Closures' },
          back_button_link: { url: '/closures/' },
        },
      },
      {
        __typename: 'ClosureBodyContent',
        id: 'content',
        closureData,
        airportData,
        arcGisFeature,
        list,
      },
      { __typename: 'PageBodyFooter', id: 'footer' },
    ],
    [closureData, airportData, arcGisFeature],
  )

  return (
    <>
      <GenericTemplate
        customType="closure"
        sliceZoneId="body"
        slicesMap={slicesMap}
        slicesMiddleware={slicesMiddleware}
        data={rawData}
        location={location}
        {...props}
      />
      <SpeedBump />
    </>
  )
}

export default ClosureTemplate

export const query = graphql`
  query($uid: String!, $arcGisObjectId: Int) {
    prismicClosure(uid: { eq: $uid }) {
      data {
        title {
          text
          html
        }
        airport {
          document {
            ...Airport
          }
        }
        type {
          document {
            ...Type
          }
        }
        zone {
          document {
            ...Zone
          }
        }
        start_date {
          text
        }
        end_date {
          text
        }
        hours {
          text
        }
        fallback_coordinates {
          latitude
          longitude
        }
        related_projects {
          project {
            document {
              ... on PrismicProject {
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          manual_project_name {
            text
          }
        }
        downloads {
          file {
            url
          }
          file_label {
            text
          }
        }
        body {
          __typename
        }
      }
    }
    ...ClosurePageBody

    arcGisFeature(properties: { OBJECTID: { eq: $arcGisObjectId } }) {
      geometry
      polylabel
      centroid
    }
  }
`
