import React from 'react'
import { graphql } from 'gatsby'
import { GenericTemplate } from '@walltowall/gatsby-theme-ww-prismic'

import { SpeedBump } from 'src/components'

import { slicesMap } from 'src/slices/PageBody'

export const PageTemplate = (props) => {
  const isProjectsPage = props.data?.prismicPage?.uid === 'projects' || false
  const lastPublishDate = props.data?.prismicPage?.last_publication_date || null

  const slicesMiddleware = React.useCallback(
    (list = []) =>
      [
        { __typename: 'PageBodyHeader', id: 'header' },
        ...list,
        !isProjectsPage && {
          __typename: 'PageBodyLastUpdated',
          id: 'last-updated',
          date: lastPublishDate,
        },
        { __typename: 'PageBodyFooter', id: 'footer' },
      ].filter(Boolean),
    [],
  )

  return (
    <>
      <GenericTemplate
        customType="page"
        sliceZoneId="body"
        slicesMap={slicesMap}
        slicesMiddleware={slicesMiddleware}
        {...props}
      />
      <SpeedBump />
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      ...PageParentRecursive
      uid
      last_publication_date(formatString: "MMM DD, YYYY")
      data {
        title {
          text
        }
        meta_title
        meta_description
        body {
          __typename
        }
      }
    }
    ...SlicesPageBody
  }
`
