import React from 'react'
import { UnpublishedPreviewTemplate } from '@walltowall/gatsby-theme-ww-prismic'

import { PageTemplate } from 'src/templates/page'
import { ClosureTemplate } from 'src/templates/closure'
import { ProjectTemplate } from 'src/templates/project'

const templateMap = {
  page: () => PageTemplate,
  closure: () => ClosureTemplate,
  project: () => ProjectTemplate,
}

export const UnpublishedPreviewPage = (props) => (
  <UnpublishedPreviewTemplate templateMap={templateMap} {...props} />
)

export default UnpublishedPreviewPage
